import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { Typography } from '@mui/material';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";

function Copyright() {
  return (
    <React.Fragment>
      <Link
        color="#fff"
        underline="none"
        href="https://alexcraciun10.github.io/craciunconcept/"
      >
        {"© "} Crăciun Concept
        {" " + new Date().getFullYear()}
      </Link>{" "}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 50,
  height: 50,
  borderRadius: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  backgroundColor: "secondary.main",
  mr: 1,
  "&:hover": {
    bgcolor: "white",
    color: "secondary.main",
  },
};

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.main" }}
    >
      <Container sx={{ my: 4, display: "flex" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} container direction="row" justifyContent="center">
            <Grid item sx={{ display: "flex" }}>
              <Box
                component="a"
                href="https://www.facebook.com/craciunconcept/"
                sx={iconStyle}
              >
                <FacebookIcon sx={{ fontSize: 48 }} />
              </Box>
              <Box
                component="a"
                href="https://www.instagram.com/craciunconcept/"
                sx={iconStyle}
              >
                <InstagramIcon sx={{ fontSize: 48 }} />
              </Box>
              <Box
                component="a"
                href="https://wa.me/40760899020"
                sx={iconStyle}
              >
                <WhatsAppIcon sx={{ fontSize: 48 }} />
              </Box>
              <Box component="a" href="tel:+40760899020" sx={iconStyle}>
                <CallIcon sx={{ fontSize: 48 }} />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            my={2}
          >
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
