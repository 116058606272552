import * as React from "react";
import withRoot from './components/withRoot';
import ProductMap from "./components/views/MapBBS";
import AppFooter from "./components/views/FooterBBS";
import ProductHero from "./components/views/HeroBBS";
import ProductPrices from "./components/views/PricesBBS";
import ProductHours from "./components/views/HoursBBS";
import AppAppBar from "./components/views/AppAppBar";

function Home() {
  return (
    <React.Fragment>
      <AppAppBar />
      <ProductHero />
      <ProductPrices />
      <ProductHours /> 
      <ProductMap />
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Home);
