import * as React from "react";

import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from '@mui/material';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";


function ProductPrices() {
  return (
    <Paper
    component="ProductPrices"
      sx={{ display: "flex", flexWrap: "wrap", bgcolor: "primary.light" }}
    >
      
      <Container sx={{ mt: 5, mb: 20, position: "relative", alignItems: 'flex-start', }}>

      <Box xs={12} >
        <Typography
          variant="h4"
          marked="center"
          align="center"
          component="h2"
          color="black"
          sx={{mt:0, mb:5}}
        >
          PRETURI
        </Typography>
      </Box>

        <List sx={{ width: "100%", display: "block"}}>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6">Tuns + Spalat + Styling</Typography>
              }
            />
            <ListItemText
              primary={<Typography variant="h6">40 RON</Typography>}
              edge="end"
              align="right"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={<Typography variant="h6">Spalat + Styling</Typography>}
            />
            <ListItemText
              primary={<Typography variant="h6">15 RON</Typography>}
              edge="end"
              align="right"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={<Typography variant="h6">Barba</Typography>}
            />
            <ListItemText
              primary={<Typography variant="h6">20 RON</Typography>}
              edge="end"
              align="right"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={<Typography variant="h6">Tata & Fiu</Typography>}
            />
            <ListItemText
              primary={<Typography variant="h6">60 RON</Typography>}
              edge="end"
              align="right"
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={<Typography variant="h6">Junior</Typography>}
            />
            <ListItemText
              primary={<Typography variant="h6">30 RON</Typography>}
              edge="end"
              align="right"
            />
          </ListItem>
        </List>
      </Container>
    </Paper>
  );
}

export default ProductPrices;
