import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Parallax } from "react-parallax";
import bh from "../assets/parallax.jpeg";
import ButtonBase from '@mui/material/ButtonBase';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const ColorButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  backgroundColor: 'rgba(0,0,0,0.7)',
  [theme.breakpoints.down('xl')]: {
    width: '100% !important',
  },
  '& .dayTitle': {
    position: 'relative',
    padding: `${theme.spacing(1)} ${theme.spacing(1)} 14px`,
  },

}));
const days =[
  {
    title: "LUNI",
    hours: "INCHIS",
  },
  {
    title: "MARTI",
    hours: "11:00-20:00",
  },
  {
    title: "MIERCURI",
    hours: "11:00-20:00",
  },
  {
    title: "JOI",
    hours: "11:00-20:00",
  },
  {
    title: "VINERI",
    hours: "11:00-20:00",
  },
  {
    title: "SAMBATA",
    hours: "11:00-17:00",
  },
  {
    title: "DUMINICA",
    hours: "INCHIS",
  },
]


export default function ProductHours() {
  return (
      <Parallax bgImage={bh} bgImageAlt="parallax" strength={500} >
        <Box xs={12} 
          backgroundColor= 'rgba(0, 0, 0, 0.5)'
          pt={4}> 
          <Typography variant="h4" marked="center" align="center" component="h2" color="white">
          PROGRAM
          </Typography>
      </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="auto"
          minHeight = "60vh"
          backgroundColor= 'rgba(0, 0, 0, 0.5)'
        >
          
          <Box sx={{ mt: 2, mb: 2, display: 'flex', flexWrap: 'wrap'}}>
        {days.map((day) => (
          <ColorButton
            key={day.title}
            style={{
              width: 200,
              margin: 8,
              height: 90,
              padding: 10,
            }}
            disabled
            flex
          >
            
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="dayTitle"
              >
                {day.title}
                <Box>
                {day.hours}
                </Box>
              </Typography>

              
            </Box>
          </ColorButton>
        ))}
      </Box>
        </Grid>
          
      </Parallax>
    
  );
}
