import { styled } from '@mui/material/styles';
import MuiToolbar from '@mui/material/Toolbar';

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: 52,
  padding: 2,
  [theme.breakpoints.up('sm')]: {
    height: 52,
  },
}));

export default Toolbar;
