import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '../ui/AppBar';
import Toolbar from '../ui/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { ReactComponent as LogoB } from '../assets/cc-bbs.svg';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  // Override media queries injected by theme.mixins.toolbar
  '@media screen': {
    minHeight: 40,
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? 'rgba(0, 0, 0, 0.0)' : 'transparent',
      color: trigger ? 'white' : 'black',
      transition: trigger ? '0.3s' : '0.5s',
      boxShadow: 'none',
    },
  });
}

function AppAppBar() {
  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar position="fixed">
          <StyledToolbar>
            <Box sx={{ flex: 0 }} />
            {/* <Link to="/home"> */}
              <LogoB height="48" />
            {/* </Link> */}
          </StyledToolbar>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
}

export default AppAppBar;
