import * as React from 'react';
import { Typography } from '@mui/material';
import ProductHeroLayout from './HeroLayout';
import backgroundImage from '../assets/hero4.jpeg';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#564339', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center" sx={{ mt: 10 }}>
        Upgrade your Haircut
      </Typography>
    </ProductHeroLayout>
  );
}
