import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from '@mui/material';
import { Paper } from "@mui/material";

function ProductMap() {
  return (
    <Paper
      component="productMap"
      sx={{ display: "flex", bgcolor: "primary.light" }}
    >
     
      <Container>
      <Box xs={12} 
          pt={4}
          pb={4}> 
          <Typography variant="h4" marked="center" align="center" component="h2" color="black">
          LOCATIE
          </Typography>
      </Box>
        <Box
          sx={{
            overflow: "hidden",
            height: "60vh",
            position: "relative",
            p: 3,
          }}
        >
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2816.721087655549!2d24.372118315681888!3d45.09144876625865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474d478f9d8475d1%3A0x16795e69cfb03d54!2sCr%C4%83ciun%20Concept%20Barber%20Shop!5e0!3m2!1sen!2sro!4v1634739043859!5m2!1sen!2sro"
            style={{
              border: 0,
              left: "0",
              top: "0",
              height: "100%",
              width: "100%",
              position: "relative",
            }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </Box>
      </Container>
    </Paper>
  );
}

export default ProductMap;
